*{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    list-style-type: none;

}
html{
    display: flex;
    min-height: 100vh;

}
body{
    display: flex;
    flex: 1;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: #fffdfb;
    font-family: 'Poppins', sans-serif;
    font-size: 16px;
}
#app{
    display: flex;
    flex-direction: column;
    flex: 1;
    width: 100%;

}
@media only screen and (orientation : portrait){
    html{
        transform: rotate(90deg);

        width: 100vh;
        top: 0;
    }
}

.popup-overlay{
    background: rgba(0,0,0,0.2) !important
}

